.article {
    background-color: #f3f6f7;
    min-width: 20%;
    border-radius: 5px;
    padding: 15px;
    padding-top: 5px;
    padding-bottom: 10px;
    overflow: hidden;
    min-height: 0px;
    margin-top: 20px;
  }
  
  .dropdown {
    /* use for reference */
    height: fit-content;
  }
  
  .dropdown-primary-enter {
  }
  
  .dropdown-primary-enter-active {
  }
  
  .dropdown-primary-exit {
  }
  
  .dropdown-primary-exit-active {
  }
  
  .flexBox1 {
    display: flex;
    justify-content: space-between;
  }
  
  .contentButtonContainer {
    display: flex;
    align-items: center;
  }
  
  .contentButton {
    height: 40px;
    width: 40px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 500ms;
  }
  
  .contentButton:hover {
    background-color: rgb(126, 125, 125, 0.45);
    border-radius: 15px;
    transition: 0.5s cubic-bezier(0.93, -0.05, 0.47, 0.89);
  }
  
  .contentButtonUp {
    width: 25px;
    height: 25px;
    transition: 0.5s ease;
  }
  
  .contentButtonDown {
    width: 25px;
    height: 25px;
    transition: 0.5s ease;
    transform: rotate(180deg);
  }
  
  .title {
    text-align: left;
    white-space: pre-line;
    font-size: 23px;
    color: #000;
  }
  
  .subTitle {
    text-align: left;
    margin-top: 5px;
    margin-bottom: 10px;
    white-space: pre-line;
    color: #000;
  }
  
  .content {
    text-align: left;
    color: black;
    white-space: pre-line;
    font-size: 15px;
    margin: 0;
  }
  
  .linkBox {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #282c34;
    color: white;
    border-radius: 5px;
  }
  